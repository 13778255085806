import React, { useEffect, useState } from "react";
import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  ListBase,
  FunctionField,
  Title,
  useDataProvider,
  Button,
  Loading,
  TopToolbar,
  ChipField,
  Link,
  ListToolbar,
  Pagination,
  BooleanField,
  DeleteButton,
  NumberField,
  TextInput,
  Form,
  NumberInput,
  useNotify
} from "react-admin";
import { Breadcrumbs, Card, Typography, Link as Link2, ListItem, ListItemText, FormControl, InputLabel, MenuItem, Select, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import moment from "moment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NumbersIcon from "@mui/icons-material/Numbers";
import CreateIcon from "@mui/icons-material/Create";
import ImportIcon from '@mui/icons-material/Upload';
import ExportIcon from "@mui/icons-material/Download";

import { utils, writeFileXLSX } from "xlsx";
import { MarginTwoTone, TextFields } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import List from '@mui/material/List';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const exporter = (data: any) => {
  const dataForExport = data.map((record: any) => {
    return {
      id: record?.id,
      LocationCode: record?.locationCode,
      Name: record?.checkRoomMember.name,
      Surname: record?.checkRoomMember.surName,
      Gsm: record?.checkRoomMember.gsmNumber,
      StartDate: moment(record.startDate).format("DD.MM.YYYY HH:mm"),
      EndDate: moment(record.endDate).format("DD.MM.YYYY HH:mm"),
      FinishDate: (record.finishDate && moment(record.finishDate).isValid()) ? moment(record.finishDate).format("DD.MM.YYYY HH:mm") : '---',
      Price: record?.price?.toFixed(2),
      Duration: record?.checkRoomPriceId,
      Status: record?.status
    };
  });

  const ws = utils.json_to_sheet(dataForExport);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "CheckRoomRecords");
  writeFileXLSX(wb, `CheckRoomRecords-${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};



const CheckRoom = () => {

  const username: any = localStorage.getItem('username');



  const [open, setOpen] = React.useState(false);

  const [newStatus, setNewStatus] = React.useState<any>("");

  const [quantity, setQuantity] = React.useState<any>("");

  const [notes, setNotes] = React.useState<any>("");


  const [checkRoomNumber, setCheckRoomNumber] = React.useState<any>("");


  const [verificationCode, setVerificationCode] = React.useState<any>("");





  const [tempItem, setTempItem] = React.useState<any>({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dataProvider = useDataProvider();
  const [data, setData] = useState<any>([]);


  const [prices, setPrices] = React.useState<any>([]);

  useEffect(() => {

    dataProvider.CheckRoomGetAllPrices().then((res: any) => {
      setPrices(res?.data);

    });

    dataProvider.CheckRoomList()
      .then(({ data }: any) => {

        let newData = data;

        if (['songulduzgun', 'fuatyazgan'].includes(username))
          newData = newData.filter((x: any) => x?.locationCode == "AA120");

        if (['fatihyazici', 'asligaygili', 'azizepolat'].includes(username))
          newData = newData.filter((x: any) => x?.locationCode == "AA118");

        if (['ozlemnurcan', 'busracilembozyel', 'semuraeyisarac'].includes(username))
          newData = newData.filter((x: any) => x?.locationCode == "AA999");


        setData(newData);
      });

  }, []);

  const listContext: any = useList({ data });



  const calcTime = (pid: any) => prices.find((x: any) => x.id == pid)?.name;


  const calcDurationTime = (x: any) => {

    const duration = moment.duration(moment().diff(x?.startDate));
    const hours = Math.floor(duration.asHours());



    let minutesAgo: any = (moment().diff(x?.startDate, 'minutes') % 60);

    return timeNumberFormat(hours) + ':' + timeNumberFormat(parseInt(minutesAgo));

  }



  const calcElapsedTime = (x: any) => {

    const duration = moment.duration(moment(x?.endDate)?.diff(moment()));
    const hours = Math.floor(duration.asHours());



    let minutesAgo: any = (moment(x?.endDate)?.diff(moment(), 'minutes') % 60);

    if (hours < 0) {

      const duration2 = moment.duration(moment()?.diff(moment(x?.endDate)));
      const hours2 = Math.floor(duration2.asHours());



      let minutesAgo2: any = (moment()?.diff(moment(x?.endDate), 'minutes') % 60);
      return timeNumberFormat(hours2) + ':' + timeNumberFormat(parseInt(minutesAgo2));

    }



    return timeNumberFormat(hours) + ':' + timeNumberFormat(parseInt(minutesAgo));

  }


  const timeNumberFormat = (tnumber: any) => {

    if (tnumber < 10) return "0" + tnumber;
    return tnumber;
  }

  const showDetail = (item: any) => {


    setNotes(item?.notes);
    setQuantity(item.quantity);
    setNewStatus(item?.status);
    setCheckRoomNumber(item?.checkRoomNumber);
    setTempItem(item);


    handleClickOpen();
  }



  const notify = useNotify();


  const saveDetail = () => {

    if ((newStatus == 'Islemde' || newStatus == 'İşlemde') && !checkRoomNumber) {
      alert('Lütfen vestiyer numarası giriniz');
      return;
    }


    if ((newStatus == 'Islemde' || newStatus == 'İşlemde') && !quantity) {
      alert('Lütfen adet giriniz');
      return;
    }



    if (newStatus == 'Teslim Edildi' && !checkRoomNumber) {
      alert('Lütfen vestiyer numarası giriniz');
      return;
    }


    if (newStatus == 'Teslim Edildi' && !verificationCode) {
      alert('Lütfen doğrulama kodu giriniz');
      return;
    }


    if (newStatus == 'Teslim Edildi' && tempItem?.verificationCode != verificationCode) {
      alert('Doğrulama kodları eşleşmiyor');
      return;
    }

    dataProvider
      .UpdateCheckRoom({
        id: tempItem?.id,
        status: newStatus,
        quantity: (quantity ? quantity : "0") + '',
        notes: notes,
        checkRoomNumber: checkRoomNumber + ''
      }
      )
      .then(({ data }: any) => {
        dataProvider.CheckRoomList()
          .then(({ data }: any) => {
            setData(data);
          });
        notify(`Save successfully`, { type: "success", undoable: false });

        handleClose();
      });
  };


  const reSend = () => {

    dataProvider
      .ReSend({
        id: tempItem?.id
      }
      )
      .then(({ data }: any) => {

        notify(`Verifivation code successfully`, { type: "success", undoable: false });

        handleClose();
      });

  }


  if (!['ozlemnurcan', 'busracilembozyel', 'semuraeyisarac', 'checkroom', 'songulduzgun', 'fuatyazgan', 'fatihyazici', 'asligaygili', 'azizepolat'].includes(username))
    return <></>;

  const calcLocation = (locationCode: any) => {

    if (locationCode == "AA118")
      return "Akasya"

    if (locationCode == "AA120")
      return "Zorlu Center"


    if (locationCode == "AA999")
      return "Terra City"


  }


  return !data ? (
    <Loading
      loadingPrimary="Loading"
      loadingSecondary="Check Room data is loading, just a moment please..."
    />
  ) : (
    <React.Fragment>
      <div role="presentation" style={{ marginTop: '1em', marginBottom: '-2em' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link2 underline="hover" color="inherit" href="/">
            EasyPoint
          </Link2>
          <Link2
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            Home
          </Link2>
          <Typography color="text.primary">Check Room</Typography>
        </Breadcrumbs>
      </div>

      <MyList actions={<ListActions data={data} />} title="Check Room">
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <FunctionField
              render={(item: any) =>
                calcLocation(item?.locationCode)
              }
              source="startDate"
              label="Location"
              textAlign="center"
            />

            <TextField source="checkRoomNumber" label="CheckRoomNumber" />
            <TextField source="checkRoomMember.name" label="Name" />
            <TextField source="checkRoomMember.surName" label="SurName" />
            <TextField source="checkRoomMember.gsmNumber" label="Gsm" />

            <FunctionField
              render={(item: any) =>
                moment(item.startDate).format("DD.MM.YYYY HH:mm")
              }
              source="startDate"
              label="Start Date"
              textAlign="center"
            />

            <FunctionField
              render={(item: any) =>
                (item?.endDate && moment(item?.endDate).isValid()) ? moment(item.endDate).format("DD.MM.YYYY HH:mm") : '---'
              }
              source="endDate"
              label="End Date"
              textAlign="center"
            />

            <FunctionField
              render={(item: any) =>
                (item.finishDate && moment(item.finishDate).isValid()) ? moment(item.finishDate).format("DD.MM.YYYY") : '---'
              }
              source="finishDate"
              label="Finish Date"
              textAlign="center"
            />

            <FunctionField
              render={(item: any) =>
                "₺ " + (parseFloat(item?.price) + parseFloat(item?.extendPrice ? item?.extendPrice : 0))?.toFixed(2)
              }
              source="price"
              label="Price"
              textAlign="center"
            />



            <FunctionField
              render={(item: any) =>
                <Chip label={moment()?.isAfter(item?.endDate) && ['İşlemde', 'Islemde'].includes(item?.status) ? 'Ödeme Gerekli' : item?.status} variant="outlined"
                  color={calcColor(item)} />
              }
              source="status"
              label="Status"
              textAlign="center"
            />

            <FunctionField
              render={(item: any) =>
                <React.Fragment>
                  <Chip label={
                    calcTime(item?.checkRoomPriceId)} variant="outlined"
                    color={calcColor(item)} />

                  {item.extendPrice > 0 &&
                    <Chip label={
                      calcTime(item?.extendPriceId)} variant="outlined"
                      color={calcColor(item)} />

                  }

                </React.Fragment>

              }
              source="status"
              label="Time"
              textAlign="center"
            />

            <FunctionField
              render={(item: any) =>

                <Chip label={

                  calcDurationTime(item)} variant="outlined"
                  color={calcColor(item)} />
              }
              source="status"
              label="Duration"
              textAlign="center"
            />

            <FunctionField
              render={(item: any) =>
                <Button onClick={() => showDetail(item)} startIcon={<VisibilityIcon />} label="Detail" />
              }
              source="status"
              label=""
              textAlign="center"
            />


          </Datagrid>
        </ListContextProvider>
      </MyList>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="lg"
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle><div>{"Details"}  </div>



        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >



            {!(tempItem?.status == 'Teslim Edildi') &&
              <React.Fragment>


                <Button color="error" variant="contained" onClick={() => reSend()} label="Re-send Verification" />
              </React.Fragment>
            }
            <DialogContentText id="alert-dialog-slide-description">
              <Form mode="onBlur" reValidateMode="onBlur">
                <ListItemText
                  primary={<span><strong>Lokasyon : </strong> {calcLocation(tempItem?.locationCode)}</span>}
                />
                <ListItemText
                  primary={<span><strong>Name Surname : </strong> {tempItem?.name} {tempItem?.surName}</span>}
                />


                <ListItemText
                  primary={<span><strong>Start Date : </strong> {moment(tempItem?.startDate).format("DD.MM.YYYY HH:mm")}</span>}
                />

                <ListItemText
                  primary={<span><strong>End Date : </strong> {moment(tempItem?.endDate).format("DD.MM.YYYY HH:mm")}</span>}
                />

                <ListItemText
                  primary={<span><strong>Price : </strong> {tempItem?.price?.toFixed(2)}</span>}
                />

                {tempItem?.extendPrice > 0 &&

                  <ListItemText
                    primary={<span><strong>Extend Price : </strong> {tempItem?.extendPrice?.toFixed(2)}</span>}
                  />
                }

                <ListItemText
                  primary={<span><strong>Duration : </strong> {calcDurationTime(tempItem)}</span>}
                />

                <ListItemText
                  primary={<span><strong>CheckRoom Number : </strong> {tempItem?.name} {tempItem?.checkRoomNumber}</span>}
                />
                <ListItemText
                  primary={<span><strong>Current Status : </strong> <Chip label={moment()?.isAfter(tempItem?.endDate) && ['İşlemde', 'Islemde'].includes(tempItem?.status) ? 'Ödeme Gerekli' : tempItem?.status} variant="filled"
                    color={calcColor(tempItem)} /></span>}
                />


                {((moment()?.isAfter(tempItem?.endDate) && ['İşlemde', 'Islemde'].includes(tempItem?.status)) || tempItem?.status == 'Teslim Edildi') &&
                  <React.Fragment>
                    <ListItemText
                      primary={<span><strong>Quantity : </strong> {tempItem.quantity}</span>}
                    />


                  </React.Fragment>
                }

                <ListItemText
                  primary={<span><strong>Notes : </strong> {tempItem.notes}</span>}
                />

                {!((moment()?.isAfter(tempItem?.endDate) && ['İşlemde', 'Islemde'].includes(tempItem?.status)) || tempItem?.status == 'Teslim Edildi') &&
                  <React.Fragment>




                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">New Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={newStatus}

                        onChange={(e) => setNewStatus(e?.target?.value)}
                      >
                        {tempItem?.status == 'Onay Bekliyor' &&
                          <MenuItem value={"Islemde"}>İşlemde</MenuItem>
                        }
                        {['İşlemde', 'Islemde'].includes(tempItem?.status) &&
                          <MenuItem value={"Teslim Edildi"}>Teslim Edildi</MenuItem>
                        }


                      </Select></FormControl>

                    <React.Fragment>
                      {['İşlemde', 'Islemde'].includes(newStatus) &&
                        <TextInput
                          fullWidth
                          required
                          style={{ marginTop: '0' }}
                          source="CheckRoomNumber"
                          label="CheckRoomNumber"
                          value={checkRoomNumber}
                          onChange={(v: any) => setCheckRoomNumber(v?.target ? v?.target?.value : v)}
                        />
                      }
                      {newStatus == 'Teslim Edildi' &&
                        <TextInput
                          fullWidth
                          required
                          style={{ marginTop: '0' }}
                          source="verifivationCode"
                          label="VerificationCode"
                          value={verificationCode}
                          onChange={(v: any) => setVerificationCode(v?.target ? v?.target?.value : v)}
                        />
                      }
                    </React.Fragment>

                    {newStatus == 'Islemde' &&
                      <NumberInput
                        fullWidth
                        source="Quantity"
                        required
                        label="Quantity"
                        style={{ marginBottom: '0' }}
                        value={quantity}
                        onChange={(v: any) => setQuantity(v?.target ? v?.target?.value : v)}
                      />
                    }


                    <TextInput
                      fullWidth
                      style={{ marginTop: '0' }}
                      multiline
                      rows={3}
                      source="Notes"
                      label="Notes"
                      value={notes}
                      onChange={(v: any) => setNotes(v?.target ? v?.target?.value : v)}
                    />

                    <Button variant="contained" fullWidth onClick={() => saveDetail()} label="Save" />

                  </React.Fragment>
                }
              </Form>
            </DialogContentText>
          </Box>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
};

const MyList = ({ children, actions, filters, title, ...props }: any) => (
  <React.Fragment {...props}>
    <div style={{ marginTop: "0" }}>
      <Title title={title} />
      <ListToolbar filters={filters} actions={actions} />
      <Card>{children}</Card>
      <Pagination />
    </div>
  </React.Fragment>
);


const ListActions = (data: any) => {

  return (
    <TopToolbar>

      <Button startIcon={<ExportIcon />} onClick={() => exporter(data.data)} style={{ marginLeft: '1em' }} label="Export" />
    </TopToolbar>
  );
};

export default CheckRoom;
function calcColor(item: any) {
  if (moment()?.isAfter(item?.endDate) && ['İşlemde', 'Islemde'].includes(item?.status))
    return 'error';
  return 'Teslim Edildi' == item?.status ? 'success' : ['İşlemde', 'Islemde'].includes(item?.status) ? 'info' : 'warning';
}

