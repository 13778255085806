import React, { useEffect, useState } from "react";
import {
  Title, Create,
  Form,
  TextInput,
  SaveButton,
  useEditContext,
  required,
  useDataProvider,
  useNotify,
} from "react-admin";
import { Grid, Card, Typography, CardContent } from "@mui/material";

import { deepOrange, deepPurple } from '@mui/material/colors';

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InventoryIcon from "@mui/icons-material/Inventory";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
// import { useAppDataProvider } from "../dataProvider";
import { CircularProgress, Avatar } from "@mui/material";
import { useTranslate } from "react-admin";
import moment from "moment";
import { getParsedCommandLineOfConfigFile } from "typescript";



const LockerStatus = (props: any) => {
  const [locationId, setLocationID] = React.useState<any>(false);

  const { locationCode }: any = "null";
  const [kutuId, setKutuId] = React.useState<any>("");
  const [locations, setLocations] = React.useState<any>(false);
  const dp = useDataProvider();
  const [expanded, setExpanded] = React.useState<string | false>(false);



  React.useEffect(() => {
    (async () => {
      const res: any = await dp.getLocations();
      if (res) {

        if (
          locationCode &&
          res.data?.filter((k: any) => k?.locationCode == locationCode).length > 0
        ) {

          let newData =  res.data.filter((t: any) => t?.locationCode == locationCode)

          
        if(localStorage.getItem('username') == "natavega")
        newData = newData.filter((x:any)=> x.locationCode == "AA104")

          setLocations(
            newData
          );
          setLocationID(locationCode);
          props.setLocationCode(locationCode);
        } else {
          let newData =res.data;
          if(localStorage.getItem('username') == "natavega")
          newData = newData.filter((x:any)=> x.locationCode == "AA104")

          setLocations(newData);
        }
      }
    })();

  }, [dp]);



  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      props.setLocationCode(locationId);
    };

  const kutuIdHandle = (v: string) => {
    setKutuId(v);
  };

  let lastLocation = "";


  const username : any= localStorage.getItem('username');

  if (!['superadmin', 'natavega', 'admin', 'desk'].includes(username))
    return <></>;


  return (
    <div style={{ marginTop: "1em" }}>
      {!locations && (
        <div
          style={{
            width: "50px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {locations?.length == 0 && <span>Lokasyon Bulunamadı</span>}

      {locations &&
        (!locationId || locations.length > 1) &&
        locations?.sort(function (a: any, b: any) {
          if (a.locationCode < b.locationCode) {
            return -1;
          }
          if (a.locationCode > b.locationCode) {
            return 1;
          }
          return 0;
        })?.map((location: any, index: number) => {

          if (lastLocation == location.locationCode)
          return;

        lastLocation = location.locationCode;

          return (
            <React.Fragment key={"__accor__" + index}>
              {index == 0 && (
                <Grid container spacing={0} style={{ maxWidth: "180px" }}>

                </Grid>
              )}

              <Accordion
                expanded={expanded === "panel" + index}
                onChange={handleChange("panel" + index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"panel" + index + "bh-content"}
                  id={"panel" + index + "bh-header"}
                >
                  <Typography sx={{ width: "50%", flexShrink: 0 }}>
                    {location?.name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {location?.districtName && location?.districtName + ","}{" "}
                    {location?.cityName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    border: "solid 1px #e8e8e8",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  {expanded === "panel" + index && (
                    <LocationDetail
                      kutuId={kutuId}
                      kutuIdHandle={kutuIdHandle}
                      location={location}

                      dp={dp}
                      {...props}

                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          );
        })}

      {locations.length == 1 && locationId && (
        <LocationDetail
          kutuId={kutuId}

          kutuIdHandle={kutuIdHandle}
          location={locations[0]}
          dp={dp}

          {...props}
        />
      )}

    </div>
  );
};

const LocationDetail = (props: any) => {
  const [lockers, setLockers] = React.useState<any>(false);

  const message = " nolu dolabinizin kiralama suresi dolmustur. Dolabinizi atiflestirip tekrar kullanabilmek icin lutfen kiralama suresini uzatin.";

  React.useEffect(() => {
    (async () => {

      // props.setLocationCode(props?.location?.locationCode);

      let res = await props?.dp?.getAdminLockers(props?.location?.locationCode);
      if (res) {

        setLockers(res.data);
      }
    })();
  }, [props?.location?.locationCode]);



  const notify = useNotify();

  const finishLocker = async (id: any) => {
    await props?.dp?.finishBox(id).then((res: any) => {
      notify(`Locker Update Successfully`, { type: "success", undoable: false });
      window.location.reload();
    });

  }


  const diffDates = (startDate: any, endDate: any) => {

    var duration = moment.duration(moment(endDate).diff(moment()));
    var hours = duration.asMinutes().toFixed(2);

    return hours;
  }

  return (
    <React.Fragment>
      {props?.location?.types?.map((item: any, index: number) => {
        return (
          <React.Fragment key={"__boxAlert__" + index}>
            <Alert severity="warning" icon={<InventoryIcon />}>
              <AlertTitle>
                <strong> {getKutuType(item?.code)}</strong> {item?.code}{" "}
                <small>
                  ({item?.width}X{item?.height}X{item?.depth} cm)
                </small>
              </AlertTitle>
              Availability
              <strong>
                {item?.availableCount}/{item?.totalCount}
              </strong>
            </Alert>

            {/* <FormControl style={{ padding: "1em" }}>
              <FormLabel id="demo-radio-buttons-group-label">
              Available Lockers
              </FormLabel> */}

            {!lockers && (
              <div
                style={{
                  width: "50px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <div style={{ padding: "2em" }}>
              <Grid container spacing={4}>

                {lockers?.length == 0 && <span>Kutu Bulunamadı</span>}

                {lockers && lockers?.length > 0 && lockers?.sort(function (a: any, b: any) {
                  if (a.lockerNumber < b.lockerNumber) {
                    return -1;
                  }
                  if (a.lockerNumber > b.lockerNumber) {
                    return 1;
                  }
                  return 0;
                }).filter((x: any) => x.typeCode == item?.code)
                  .map((locker: any, i: number) => {
                    function parseGsm(gsm: any): React.ReactNode {
                      return gsm?.substring(0, 5) + "XXX" + gsm?.substring(gsm?.length - 4)
                    }

                    return (
                      <Grid item xs={4}>
                        <div style={{ border: "1px solid #000", display: "table-cell", padding: "1em", width: "400px" }} >

                          {!locker?.isAvailable && moment().diff(moment(locker?.endDate), 'minutes') > 0 ?

                            <React.Fragment>

                              <Alert severity="error">FULL - TIMEOUT</Alert>


                            </React.Fragment>
                            :
                            <React.Fragment>
                              {!locker?.isAvailable &&
                                <Alert severity="error">FULL</Alert>
                              }

                              {locker?.isAvailable &&
                                <Alert severity="success">EMPTY</Alert>
                              }


                            </React.Fragment>
                          }



                          <div style={{ float: "left" }}>
                            <Avatar sx={{ bgcolor: deepOrange[500] }} style={{ margin: "1em" }}> {locker?.lockerNumber}</Avatar>
                          </div>

                          <div style={{ float: "left", width: "285px", textAlign: "center" }}>

                            {(!locker?.gsm && !locker?.email) && <span><br /><br />Kiralama Kaydı Bulunamadı<br /><br /><br /></span>}
                            {(locker?.gsm || locker?.email) &&



                              <div style={{ float: "left", padding: "1em", paddingTop: "0", textAlign: "center" }}>
                                <span style={{ fontWeight: "bold" }}>Last Rental</span><br />
                                {localStorage.getItem('username') == "superadmin" ? (locker?.gsm || locker?.email) :  (locker?.gsm ? parseGsm(locker?.gsm) : parseMail(locker?.email))}<br />
                                Start Date : {moment(locker?.startDate).format("DD.MM.YYYY HH:mm")}<br />
                                End Date : {moment(locker?.endDate).format("DD.MM.YYYY HH:mm")}<br />
                                {moment(locker?.endDate).diff(moment()) > 0 && <span>Remaining Time : {diffDates(locker?.startDate, locker?.endDate)} minute</span>}

                              </div>
                            }

                          </div>
                          <div style={{ textAlign: "center" }}>
                            {!locker?.isAvailable && locker?.id &&
                              <Button size="small" color="success" onClick={() => finishLocker(locker?.id)} variant="outlined">Kontrol Et Müsait Hale Getir</Button>
                            }
                            {!locker?.isAvailable && moment().diff(moment(locker?.endDate), 'minutes') > 0 && moment().diff(moment(locker?.endDate), 'minutes') <= 720 && locker?.gsm &&
                              <Button
                                size="small" color="warning" style={{ marginTop: "1em" }} onClick={() => window.location.href = '/#/sendsms?a=1&gsmNumbers=' + btoa(locker?.gsm) + "&message=" + locker?.lockerNumber + message} variant="outlined">Müşteriyi Uzatma İçin Bilgilendir</Button>
                            }

                          </div>
                        </div>


                      </Grid>
                    );
                  })}

              </Grid>
            </div>
          </React.Fragment>
        );
      })}

    </React.Fragment>
  );
};

const getKutuType = (typeCode: string) => {
  const box = boxes?.find((x) => x.key == typeCode);
  if (box) return box.value;
  return typeCode;
};

const boxes = [
  { key: "S", value: "Small" },
  { key: "M", value: "Medium" },
  { key: "L", value: "Large" },
  { key: "XL", value: "X Large" },
];



function parseMail(mail : any){

  if (!mail) return "";
  let name = mail?.indexOf("@")>0 ? mail?.split("@")[0] : mail;
  name = name?.substring(0, 5) + "XXX" + name?.substring(name?.length - 4);
  const uzanti = mail?.indexOf("@")>0 ? mail?.split("@")[1] : "";

  return name + "@" + uzanti;
}

export default LockerStatus;
