import React, { useEffect, useState } from "react";
import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  ListBase,
  FunctionField,
  Title,
  useDataProvider,
  Button,
  Loading,
  TopToolbar,
  ChipField,
  Link,
  ListToolbar,
  Pagination,
  BooleanField,
  DeleteButton,
} from "react-admin";
import { Breadcrumbs, Card, Typography, Link as Link2 } from "@mui/material";
import Chip from "@mui/material/Chip";
import moment from "moment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NumbersIcon from "@mui/icons-material/Numbers";
import CreateIcon from "@mui/icons-material/Create";
import ImportIcon from '@mui/icons-material/Upload';
import ExportIcon from "@mui/icons-material/Download";

import { utils, writeFileXLSX } from "xlsx";
import { MarginTwoTone } from "@mui/icons-material";

const exporter = (data : any) => {
  const dataForExport = data.map((record : any) => {
    return {
      id: record?.id,
      LocationCode : record?.locationCode,
      OperationType: record?.operationType,
      StartDate: record?.startDate,
      EndDate: record?.endDate,
      DiscountType: record?.discountType,
      UseType: record?.useType,
      MaxUse: record?.maxUse,
      UseCount: record?.useCount,
      CampaignName: record?.campaignName,
      Code: record?.code,
      RateAmount : record?.rateAmount,
      Status: record?.status
    };
  });

  const ws = utils.json_to_sheet(dataForExport);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Campaigns");
  writeFileXLSX(wb, `Campaigns-${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};



const CampaignList = () => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
  
      dataProvider.CampaignList()
        .then(({ data }: any) => {
          setData(data);
        });
 
  }, []);

  const listContext : any = useList({ data });


  const username : any= localStorage.getItem('username');

  if (!['superadmin', 'natavega', 'admin', 'desk'].includes(username))
    return <></>;

  return !data? (
    <Loading
      loadingPrimary="Loading"
      loadingSecondary="Campaign data is loading, just a moment please..."
    />
  ) : (
    <React.Fragment>
      <div role="presentation" style={{marginTop:'1em', marginBottom: '-2em'}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link2 underline="hover" color="inherit" href="/">
          EasyPoint
        </Link2>
        <Link2
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Home
        </Link2>
        <Typography color="text.primary">Coupon List</Typography>
      </Breadcrumbs>
    </div>
    
    <MyList actions={<ListActions data={data} />} title="Campaign Coupon">
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="ID" />
          <TextField source="locationCode" label="Location Code" />
          <TextField source="campaignName" label="Campaign Name" />
          <TextField source="gsmEmail" label="GSM / Email" />
          <TextField source="discountType" label="Discount Type" />
          <TextField source="useType" label="Use Type" />
          <TextField source="maxUse" style={{textAlign:'center'}} label="Max Use" />
          <FunctionField
            render={(item: any) =>
              moment(item.startDate).format("DD.MM.YYYY")
            }
            source="startDate"
            label="Start Date"
            textAlign="center"
          />
          <FunctionField
            render={(item : any) =>
              moment(item.endDate).format("DD.MM.YYYY")
            }
            source="endDate"
            label="End Date"
            textAlign="center"
          />
           <TextField source="rateAmount" label="Rate / Amount" />
          <ChipField
            icon={<LockOpenIcon />}
            source="code"
            label="Code"
            style={{textAlign:'center'}} 
          />
           <BooleanField
            source="status"
            label="Status"
            style={{textAlign:'center'}} 
          />
          <DeleteButton mutationMode={"optimistic"} resource="campaign" onClick={() => {
            setTimeout(() => {
              window.location.href = "/#/campaigns";
            }, 500);
          }} />
        </Datagrid>
      </ListContextProvider>
    </MyList>

    </React.Fragment>
  );
};

const MyList = ({ children, actions, filters, title, ...props }: any) => (
    <React.Fragment {...props}>
      <div style={{ marginTop: "0" }}>
        <Title title={title} />
        <ListToolbar filters={filters} actions={actions} />
        <Card>{children}</Card>
        <Pagination />
      </div>
    </React.Fragment>
  );


const ListActions = (data: any) => {
     
    return (
      <TopToolbar>
        <Link variant="button"  to="/couponCreate" label="Create"><CreateIcon style={{fontSize:"15px"}} /> Create</Link>
        <Link variant="button"  to="/fileInput" label="Import"><ImportIcon style={{fontSize:"15px", marginTop:'3px', marginLeft:'1em'}} /> Import</Link>
        <Button startIcon={<ExportIcon />} onClick={() => exporter(data.data)} style={{marginLeft:'1em'}} label="Export" />
      </TopToolbar>
    );
  };

export default CampaignList;
