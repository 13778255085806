

const authProvider = {
    login: ({ username, password }) => {


        if ((username == 'checkroom' && password == '445566') ||
        (username == 'songulduzgun' && password == 'Easy445566') ||
        (username == 'fuatyazgan' && password == 'Easy445566') ||

        (username == 'fatihyazici' && password == 'Easy445566') ||
        (username == 'asligaygili' && password == 'Easy445566') ||
        (username == 'azizepolat' && password == 'Easy445566') ||

        (username == 'ozlemnurcan' && password == 'Easy445566') ||
        (username == 'busracilembozyel' && password == 'Easy445566') ||
        (username == 'semuraeyisarac' && password == 'Easy445566') ||
        
        (username == 'superadmin' && password == 'easypointAdmin445566') ||(username == 'natavega' && password == 'Nv445566') || (username == 'admin' && password == 'easypointAdmin') || (username == 'desk' && password == 'easy445566')) {
            localStorage.setItem('username', username);
            return Promise.resolve();
        }else{
            return Promise.reject();
        }




      
    },
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
        checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'Admin',
        }),
    getPermissions: () => Promise.resolve(''),
  };
   

  export default authProvider;